<div *ngIf="contactData.contact !== undefined && loaded" class="selected-contact-wrapper">
  <ng-container *ngIf="screen.width < 475; then mobileTemplate"></ng-container>
  <ng-container *ngIf="screen.width >= 475 && screen.width <= 1024; then tabletTemplate"></ng-container>
  <ng-container *ngIf="screen.width > 1024; then desktopTemplate"></ng-container>
</div>

<ng-template #mobileTemplate>
  <div class="component-structure contact-header-mobile">
    <div class="contact-header-mobile-text">{{contactName}}</div>

    <div class="contact-header-mobile-buttons">
      <!-- <button *ngIf="contactBe.isChanged && hasEditRights" class="contact-save-button"  igxButton="raised"
         [style.color]="'white'" [style.background]="'#AEC965'" igxRipple="white" (click)="onSave($event)">LAGRE</button> -->

      <button *ngIf="contactBe.isChanged" class="contact-save-button" igxButton="contained" [style.color]="'white'"
        [style.background]="'#AEC965'" igxRipple="white" [disabled]="!hasEditRights" (click)="onSave()">LAGRE</button>
    </div>
  </div>
  <div style="background-color: red; margin-bottom: 63px;"></div>
  <igx-accordion #accordion [singleBranchExpand]="false">
    <igx-expansion-panel [collapsed]="true" class="component-structure margin-bottom">
      <igx-expansion-panel-header>
        <igx-expansion-panel-title #mSummary>Kontaktsammendrag</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-contact-summary [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-summary>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
    <igx-expansion-panel [collapsed]="true" class="component-structure margin-bottom">
      <igx-expansion-panel-header>
        <igx-expansion-panel-title>Kontaktinformasjon</igx-expansion-panel-title>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <app-contact-information [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-information>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
  </igx-accordion>
  <igx-tabs class="igx-box-shadow tab-wrap">
    <igx-tab-item [selected]="true">
      <igx-tab-header>
        <span igxTabHeaderLabel>Saker</span>
        <i class="material-icons icon-default">folder</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-cases #cases [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-cases>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
      <igx-tab-header>
        <span igxTabHeaderLabel>Adresse/Tlf</span>
        <i class="material-icons icon-default">contact_emergency</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-contactinfo [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-contactinfo>
      </igx-tab-content>
    </igx-tab-item>
  </igx-tabs>
</ng-template>

<ng-template #verticalContactDetailsTemplate>
  <igx-tabs class="igx-box-shadow" [tabAlignment]="'justify'">
    <igx-tab-item [selected]="true">
      <igx-tab-header>
        <span igxTabHeaderLabel>{{ contactBe.getIsUser() ? 'Medarbeider' : contactBe.getIsCompany() ? 'Bedrift'
          : 'Person' }}</span>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-information class="tablet-contact-info" [componentTitle]="null" [data]="contactBe"
          [hasEditRights]="hasEditRights"></app-contact-information>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
      <igx-tab-header>
        <span igxTabHeaderLabel>Kontaktinformasjon</span>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-summary class="tablet-contact-sum" #summary [title]="null" [data]="contactBe"
          [hasEditRights]="hasEditRights"></app-contact-summary>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item #toggle>
      <igx-tab-header class="flex-row">
        <span igxTabHeaderLabel>skjul</span>
      </igx-tab-header>
      <igx-tab-content>
      </igx-tab-content>
    </igx-tab-item>
  </igx-tabs>
</ng-template>

<ng-template #tabletTemplate>
  <igx-accordion>
    <igx-expansion-panel #panel [collapsed]="!expanded">
      <igx-expansion-panel-header>
        <div class="flex-row space-between component-structure head-spacer ">
          <div class="flex-col">
            <app-title [value]="getContactSectorById(contactBe.getContactSectorId())"></app-title>
            <app-heading [value]="contactName"></app-heading>
          </div>
          <div class="flex-row toggle-expand">
            <!-- <button *ngIf="contactBe.isChanged && hasEditRights" class="contact-save-button" 
              igxButton="raised"  [style.color]="'white'" [style.background]="'#AEC965'" igxRipple="white"
              (click)="onSave($event)">LAGRE</button> -->

            <button *ngIf="contactBe.isChanged" class="contact-save-button" igxButton="contained"
              [style.color]="'white'" [style.background]="'#AEC965'" igxRipple="white" [disabled]="!hasEditRights"
              (click)="onSave()">LAGRE</button>
          </div>
        </div>
      </igx-expansion-panel-header>
      <igx-expansion-panel-body>
        <igx-tabs class="igx-box-shadow" [tabAlignment]="'justify'">
          <igx-tab-item [selected]="true">
            <igx-tab-header>
              <span igxTabHeaderLabel>{{ contactBe.getIsUser() ? 'Medarbeider' : contactBe.getIsCompany() ? 'Bedrift'
                : 'Person' }}</span>
            </igx-tab-header>
            <igx-tab-content>
              <app-contact-information class="tablet-contact-info" [componentTitle]="null" [data]="contactBe"
                [hasEditRights]="hasEditRights"></app-contact-information>
            </igx-tab-content>
          </igx-tab-item>
          <igx-tab-item>
            <igx-tab-header>
              <span igxTabHeaderLabel>Kontaktinformasjon</span>
            </igx-tab-header>
            <igx-tab-content>
              <app-contact-summary class="tablet-contact-sum" #summary [title]="null" [data]="contactBe"
                [hasEditRights]="hasEditRights"></app-contact-summary>
            </igx-tab-content>
          </igx-tab-item>

        </igx-tabs>
      </igx-expansion-panel-body>
    </igx-expansion-panel>
  </igx-accordion>
  <igx-tabs class="igx-box-shadow" [tabAlignment]="'justify'">
    <igx-tab-item *ngIf="contactBe.getIsUser() === false || contactBe.getIsUser() === null">
      <igx-tab-header>
        <span igxTabHeaderLabel>Saker</span>
        <i translate="no" class="material-symbols-outlined icon-default">folder</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-cases #cases [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-cases>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
      <igx-tab-header>
        <span igxTabHeaderLabel>Adresse/Tlf</span>
        <i translate="no" class="material-symbols-outlined icon-default">contact_emergency</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-contactinfo [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-contactinfo>
      </igx-tab-content>
    </igx-tab-item>
  </igx-tabs>
</ng-template>

<ng-template #horizontalContactDetailsTemplate>
  <div class="tablet-contact-details">
    <app-contact-information class="tablet-contact-info"
      [componentTitle]="contactBe.getIsUser() ? 'Medarbeider:' : contactBe.getIsCompany() ? 'Bedrift:' : 'Person:'"
      [data]="contactBe" (nationalNumberValidity)="handleNationalNumberValidity($event)"
      [hasEditRights]="hasEditRights"></app-contact-information>
    <app-contact-summary class="tablet-contact-sum" #summary [title]="'Kontaktinformasjon:'" [data]="contactBe"
      [hasEditRights]="hasEditRights"></app-contact-summary>
  </div>
</ng-template>



<ng-template #desktopTemplate>
  <div class="flex-row space-between component-structure contact-header">
    <div class="flex-col">
      <app-title [value]="contactName"></app-title>
      <app-heading [value]="getContactSectorById(contactBe.getContactSectorId())"></app-heading>
    </div>
    <div class="flex-row toggle-expand">
      <!-- @if(contactBe.getOrganizationNumber()?.length === 9) {
        <button class="contact-save-button" igxButton="contained"
        [style.color]="'white'" [style.background]="'#BCBCBC'" igxRipple="white" [disabled]="!hasEditRights"
       (click)="checkbrreg()">SJEKK BR.REG</button>
      } -->
      @if (contactBe.isChanged) {
      <button class="contact-save-button" igxButton="contained" [style.color]="'white'" [style.background]="'#AEC965'"
        igxRipple="white" [disabled]="!hasEditRights" (click)="onSave()">LAGRE</button>
      }


      <ng-container *ngIf="expanded === false; then more; else less"></ng-container>
    </div>
  </div>
  <ng-container *ngIf="expanded === true && screen.width > 550; then show;">
  </ng-container>

  <igx-tabs class="igx-box-shadow tab-height">
    <igx-tab-item *ngIf="contactBe.getIsUser() === false || contactBe.getIsUser() === null">
      <igx-tab-header>
        <span igxTabHeaderLabel>Saker</span>
        <i translate="no" class="material-symbols-outlined icon-default">folder</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-cases #cases [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-cases>
      </igx-tab-content>
    </igx-tab-item>
    <igx-tab-item>
      <igx-tab-header>
        <span igxTabHeaderLabel>Adresse/Tlf</span>
        <i translate="no" class="material-symbols-outlined icon-default">contact_emergency</i>
      </igx-tab-header>
      <igx-tab-content>
        <app-contact-contactinfo [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-contactinfo>
      </igx-tab-content>
    </igx-tab-item>
  </igx-tabs>
</ng-template>


<ng-template #less>
  <div class="flex-row icon-default toggle-expand" (click)="changeExpand()">
    <app-heading [value]="'Skjul kontaktinformasjon'"></app-heading>
    <i class="material-icons margin-left">arrow_circle_up</i>
  </div>
</ng-template>

<ng-template #more>
  <div class="flex-row icon-default toggle-expand" (click)="changeExpand()">
    <app-heading [value]="'Vis kontaktinformasjon'"></app-heading>
    <i class="material-icons margin-left">arrow_circle_down</i>
  </div>
</ng-template>

<ng-template #show>
  <div class="selected-contact-grid body-spacer">
    <app-contact-information
      [componentTitle]="contactBe.getIsUser() ? 'Medarbeider:' : contactBe.getIsCompany() ? 'Bedrift:' : 'Person:'"
      [data]="contactBe" [hasEditRights]="hasEditRights"></app-contact-information>
    <app-contact-summary #summary [title]="'Kontaktinformasjon:'" [data]="contactBe"
      [hasEditRights]="hasEditRights"></app-contact-summary>
  </div>
</ng-template>

<igx-dialog #nationalNumberErrorDialog title="Feil fødselsnummer"
  message="Du må taste inn minst fødselsdato (DDMMÅÅ), eller hele fødselsnummeret (11 siffer). Skal ikke inneholde bokstaver."
  leftButtonLabel="OK" (leftButtonSelect)="nationalNumberErrorDialog.close()">
</igx-dialog>