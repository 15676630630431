<div class="content-wrapper conditional-component-structure">
  @if(componentTitle){
  <app-heading [value]="componentTitle"></app-heading>
  }
  <div class="contact_upper-grid m-top">
    <igx-input-group class="contact_number" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input igxInput name="contactAdr" type="number" [(ngModel)]="contactNumber"
        [disabled]="!hasEditRights || isCoWorker" [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="contactAdr">Kontaktnr:</label>
    </igx-input-group>


    <div *ngIf="cData.contactSectors && !isCoWorker" class="contact_sector simple-select-wrapper">
      <label #sectorLabel igxLabel class="simple-select-label simple-select-label--selected">Kontaktsektor:</label>
      <igx-simple-combo #sector [data]="cData.contactSectors" [type]="'border'"
        [(ngModel)]="contactSectorId" (opening)="combo.comboOpening(sectorLabel)"
        (closing)="combo.comboClosing(sectorLabel, contactSectorId)" (selectionChanging)="onContactSectorChange($event)"
        [displayKey]="'Name'" [valueKey]="'Id'" [disabled]="!hasEditRights || isCoWorker"
        [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" [style.--ig-size]="'var(--ig-size-small)'"></igx-simple-combo>
    </div>

    <div *ngIf="isCoWorker" class="contact_sector simple-select-wrapper">
      <igx-input-group class="" type="border" [style.--ig-size]="'var(--ig-size-small)'">
        <input igxInput name="coworker" type="text" value="Medarbeider" [disabled]="true"
          [ngStyle]="{'cursor': 'not-allowed'}" />
        <label igxLabel for="coworker">Kontaktsektor:</label>
      </igx-input-group>
    </div>

    <div *ngIf="cData.allEmployees" class="contact_client-responsible simple-select-wrapper">

      <label #responsibleLabel igxLabel
        class="simple-select-label simple-select-label--selected">Klientansvarlig:</label>
      <igx-simple-combo #responsible [data]="cData.allEmployees" [type]="'border'"
        (opening)="combo.comboOpening(responsibleLabel)"
        (closing)="combo.comboClosing(responsibleLabel, responsibleUserId)"
        (ngModelChange)="data.setResponsibleUserId($event)" [displayKey]="'ContactName'" [valueKey]="'Id'"
        [(ngModel)]="responsibleUserId" [disabled]="clientResponsibleAccessHandler" [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>

  </div>

  <div class="contact_lower-grid m-top">

    <igx-input-group *ngIf="data.getIsCompany() === false" class="contact_first-name"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <!-- <input #firstNameInput igxInput name="firstName" type="text" [(ngModel)]="firstName"
        (ngModelChange)="data.setFirstName(firstNameInput.value)" [disabled]="!hasEditRights || isCoWorker"
        [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="firstName">Fornavn:</label> -->
      <input #firstNameInput igxInput name="firstName" type="text" [(ngModel)]="firstName"
        (ngModelChange)="data.setFirstName(firstNameInput.value)" [disabled]="!hasEditRights || isCoWorker"
        [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="firstName">Fornavn:</label>
    </igx-input-group>

    <igx-input-group *ngIf="data.getIsCompany() === false" class="contact_last-name"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #lastNameInput igxInput name="lastName" type="text" [(ngModel)]="lastName"
        (ngModelChange)="data.setLastName(lastNameInput.value)" [disabled]="!hasEditRights || isCoWorker"
        [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="lastName">Etternavn:</label>
    </igx-input-group>


    <igx-input-group *ngIf="data.getIsCompany() === true" class="contact_name" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #nameInput igxInput name="name" type="text" [(ngModel)]="name"
        (ngModelChange)="data.setName(nameInput.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="name">Navn:</label>
    </igx-input-group>

    <igx-input-group class="contact_name-2" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #name2Input igxInput name="name2" type="text" [(ngModel)]="name2"
        (ngModelChange)="data.setName2(name2Input.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="name2">Navn 2:</label>
    </igx-input-group>

    <igx-input-group class="contact_pseudo" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #pseudoNameInput igxInput name="pseudoName" type="text" [(ngModel)]="pseudoName"
        (ngModelChange)="data.setPseudoName(pseudoNameInput.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="pseudoName">Pseudo:</label>
    </igx-input-group>


    <igx-input-group *ngIf="data.getIsCompany() === false" class="contact_occupation"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #occupationInput igxInput name="occupation" type="text" [(ngModel)]="occupation"
        (ngModelChange)="data.setOccupation(occupationInput.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="occupation">Yrke:</label>
    </igx-input-group>

    <igx-input-group *ngIf="data.getIsCompany() === false" class="contact_title" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #titleInput igxInput name="title" type="text" [(ngModel)]="title"
        (ngModelChange)="data.setTitle(titleInput.value)" [disabled]="!hasEditRights || isCoWorker"
        [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="title">Tittel:</label>
    </igx-input-group>

    <igx-input-group *ngIf="data.getIsCompany() === false" class="contact_initials"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #initialsInput igxInput name="initials" type="text" [(ngModel)]="shortForm"
        (ngModelChange)="data.setShortForm(initialsInput.value)" (change)="data.setTitle(initialsInput.value)"
        [disabled]="!hasEditRights || isCoWorker" [ngStyle]="isCoWorker ? { 'cursor': 'not-allowed' } : {}" />
      <label igxLabel for="title">Initialer:</label>
    </igx-input-group>

    <div class="simple-select-wrapper contact_nationality" *ngIf="data.getIsCompany() === false">
      <label #nationalityLabel igxLabel class="simple-select-label simple-select-label--selected">Nasjonalitet:</label>
      <igx-simple-combo #nationalityInput [data]="cData.countries"
        (opening)="combo.comboOpening(nationalityLabel)"
        (closing)="combo.comboClosing(nationalityLabel, selectedCountryCode)" displayKey="Nationality" valueKey="Code"
        [(ngModel)]="selectedCountryCode" type="border" (ngModelChange)="onCountryChange($event)"
        [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>

    <igx-input-group *ngIf="data.getIsCompany() === true" class="contact_shortform"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #shortFormInput igxInput name="initials" type="text" [(ngModel)]="shortForm"
        (ngModelChange)="data.setShortForm(shortFormInput.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="title">kortform:</label>
    </igx-input-group>



    <div *ngIf="data.getIsCompany() === true && cData.businessSectors"
      class="simple-select-wrapper contact_business-sector">
      <label #bizSecInputLabel igxLabel class="simple-select-label simple-select-label--selected">Bransje:</label>
      <igx-simple-combo #bizSecInput [data]="cData.businessSectors"
        (opening)="combo.comboOpening(bizSecInputLabel)"
        (closing)="combo.comboClosing(bizSecInputLabel, businessSectorId)" [displayKey]="'Name'" [valueKey]="'Id'"
        [(ngModel)]="businessSectorId" [type]="'border'" (ngModelChange)="businessSectorChange(bizSecInput.value)"
        [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>

    <div *ngIf="data.getIsCompany() === false" class="simple-select-wrapper contact_gender">
      <label #genderLabel igxLabel class="simple-select-label simple-select-label--selected">Kjønn:</label>
      <igx-simple-combo [data]="genders" [displayKey]="'label'" [valueKey]="'value'"
        (ngModelChange)="data.setGender($event)" [(ngModel)]="contactGender" [type]="'border'"
        [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>

    <igx-date-picker #datePicker *ngIf="data.getIsCompany() === false" class="contact_birthdate"
      [(ngModel)]="dateOfBirth" inputFormat="dd.MM.yyyy" type="border" todayButtonLabel="I dag"
      cancelButtonLabel="Avbryt" (ngModelChange)="data.setDateOfBirth(toLocalIsoString(datePicker.value))"
      (click)="datePicker.open()" [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
      <label igxLabel for="date">Fødselsdato:</label>
    </igx-date-picker>


    <div class="contact_national-identity-number">

      <igx-input-group *ngIf="data.getIsCompany() === false" type="border" [style.--ig-size]="'var(--ig-size-small)'">
        <input #idnumber igxInput type="text" name="contactNationalIdentityNumber" [(ngModel)]="nationalIdentityNumber"
          (ngModelChange)="data.setNationalIdentityNumber(idnumber.value)" appBirthDayNumberValidator
          #nationalIdentityNumberCtrl="ngModel" minlength="6" maxlength="11" (blur)="inputBlurred=true"
          (focus)="inputBlurred=false" [disabled]="!hasEditRights" />
        <label igxLabel for="title">Fødselsnummer:</label>
      </igx-input-group>

      @if(inputBlurred && nationalIdentityNumberCtrl.errors && nationalIdentityNumberCtrl.touched){
      <div class="error-message">
        * Fødselsnummeret er ugyldig.
      </div>

      }

    </div>

    <igx-input-group *ngIf="data.getIsCompany() === true" class="contact_org-number"
      type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #orgnumber igxInput type="number" [(ngModel)]="organizationNumber" minlength="9" maxlength="9"
        (ngModelChange)="data.setOrganizationNumber(orgnumber.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="title">Org.nummer:</label>
    </igx-input-group>

    <div *ngIf="data.getIsCompany() === true" class="simple-select-wrapper contact_org-structure">
      <label #orgidLabel igxLabel class="simple-select-label simple-select-label--selected">Org.form:</label>
      <igx-simple-combo [data]="cData.organisationalStructures"
        (opening)="combo.comboOpening(orgidLabel)" (closing)="combo.comboClosing(orgidLabel, organizationalStructureId)"
        [displayKey]="'Name'" [valueKey]="'Id'" (ngModelChange)="organizationalStructureChange($event)"
        [(ngModel)]="organizationalStructureId" [type]="'border'" [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
      </igx-simple-combo>
    </div>


    <div *ngIf="contactSectorId!.toUpperCase() === '088B3946-96DA-43A7-95A9-99BB7269D49C'" class="contact_enk-org-nr">
      <input #orgnumber igxInput type="number" [(ngModel)]="organizationNumber"
        (ngModelChange)="data.setOrganizationNumber(orgnumber.value)" [disabled]="!hasEditRights" />
      <label igxLabel for="title">Org.nummer:</label>
    </div>

    <div *ngIf="contactSectorId!.toUpperCase() === '1321FC0D-718F-4300-9230-7D079348942B'" class="contact_public">
      <input class="spacer" type="checkbox" (change)="data.setPublicCompany($event)" [(ngModel)]="publicCompany"
        name="publicCompany" [disabled]="!hasEditRights">
      <label for="publicCompany">Børsnotert</label>
    </div>

  </div>
</div>