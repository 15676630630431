<!-- <div class="conditional-component-structure contact-summary">
    <app-heading *ngIf="title" [value]="title"></app-heading>
    <div class="summary-grid">
        <div class="flex-col">
            <div class="summary-row" *ngFor="let phone of phoneNumbers">
                <strong class="leadingtxt">{{phone.name}}:</strong>
                <span>
                    <a class="normalize-links" [href]="'tel:'+ phone.value">{{phone.value}}</a>
                </span>
            </div>
            <div class="summary-row" *ngFor="let email of emailAddresses">
                <strong class="leadingtxt">{{email.name}}: </strong> <span> <a class="normalize-links"
                        [href]="'mailto:'+ email.value">{{email.value}}</a></span>
            </div>
            <div class="summary-row" *ngFor="let address of addresses">
                <strong class="leadingtxt">{{address.name}}: </strong> <span><a class="normalize-links" target="_blank"
                        [href]="'http://maps.google.com/?q=' + address.value">{{address.value}}</a></span>
            </div>
        </div>
    </div>
</div> -->


<div class="conditional-component-structure contact-summary">
  <app-heading *ngIf="title" [value]="title"></app-heading>
  <div class="summary-grid">
    <div class="flex-col">
      <div class="summary-row" *ngFor="let phone of phoneNumbers">
        <strong class="leadingtxt">{{phone.name}}:</strong>
        <span class="copy-wrapper">
          <a class="normalize-links" [href]="'tel:'+ phone.value">{{phone.value}}</a>
          <button class="copy-button" (click)="copyText(phone.value, $event)">
            <i translate="no" class="material-symbols-outlined copy-icon text-lg">content_copy</i>
            <span class="tooltip-text">kopiere</span>
          </button>
        </span>
      </div>

      <div class="summary-row" *ngFor="let email of emailAddresses">
        <strong class="leadingtxt">{{email.name}}: </strong>
        <span class="copy-wrapper">
          <a class="normalize-links" [href]="'mailto:'+ email.value">{{email.value}}</a>
          <button class="copy-button" (click)="copyText(email.value, $event)">
            <i translate="no" class="material-symbols-outlined copy-icon text-lg">content_copy</i>
            <span class="tooltip-text">kopiere</span>
          </button>
        </span>
      </div>

      <div class="summary-row" *ngFor="let address of addresses">
        <strong class="leadingtxt">{{address.name}}: </strong>
        <span class="copy-wrapper">
          <a class="normalize-links" target="_blank"
            [href]="'http://maps.google.com/?q=' + address.value">{{address.value}}</a>
          <button class="copy-button" (click)="copyText(address.value, $event)">
            <i translate="no" class="material-symbols-outlined copy-icon text-lg">content_copy</i>
            <span class="tooltip-text">kopiere</span>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>