import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ContactBE } from 'src/app/classes/ContactBE';
import { DatalexClient, IContactAddressBE, IContactPhoneBE, ICountryBE, IPostalAddressBE } from '@datalex-software-as/datalex-client';
import { CountryService } from 'src/app/services/country.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { AddressTypeIdEnum, AddressTypes } from 'src/app/util/AddressTypeUtil';
import { PhoneNumberTypesArray, PhoneNumberTypeIdEnum } from 'src/app/util/PhoneNumberTypeUtil';
import { ContactDataService } from '../contact-data.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { EmptyGuid } from 'src/app/util/RandomId';
import { NgModel, FormsModule } from '@angular/forms';
import { DlxAddressInputComponent } from '../../../UI/dlx-address-input/dlx-address-input.component';
import { NgIf } from '@angular/common';
import { EmailValidatorDirective } from '../../../../directives/emailvalidator.directive';
import { IgxLabelDirective, IgxSimpleComboComponent, IgxInputGroupComponent, IgxInputDirective, IgxInputGroupModule } from '@infragistics/igniteui-angular';

@Component({
  selector: 'app-contact-contactinfo',
  templateUrl: './contact-contactinfo.component.html',
  styleUrls: ['./contact-contactinfo.component.scss'],
  standalone: true,
  imports: [IgxLabelDirective, IgxInputGroupModule, IgxSimpleComboComponent, FormsModule, IgxInputGroupComponent, IgxInputDirective, EmailValidatorDirective, NgIf, DlxAddressInputComponent]
})
export class ContactContactinfoComponent implements OnInit {
  @Input() hasEditRights: boolean = false;

  @ViewChild('emailAddressCtrl') emailAddressCtrl!: NgModel;
  @ViewChild('invoiceEmailAddressCtrl') invoiceEmailAddressCtrl!: NgModel;



  constructor(public contactData: ContactDataService, public country: CountryService, private dlxClient: DatalexClient, public screen: ScreenDimensionService, public combo: ComboSelectFunctionsService) { }
  _data!: ContactBE;
  @Input() set data(value: ContactBE) {
    this._data = value;
    
    this.extractAddresses(JSON.parse(JSON.stringify(value.getAddresses())));
    this.setPhoneArray()
  }

  get data() {
    return this._data;
  }

  initAttempts = 0;

  inititalized = false;

  emailAddress!: string | null;
  invoiceEmailAddress!: string | null;
  webAddress!: string | null;

  ehfInvoice!: boolean | null;
  vatObligated!: boolean | null;
  digipostInvoice!: boolean | null;
  emailInvoice!: boolean | null;
  digipostInvoiceIndetermined: boolean = true;
  emailInvoiceIndetermined = true;

  showEmailErrorMessage: boolean = false;
  showInvoiceEmailErrorMessage: boolean = false;


  ngOnInit(): void {
    this.initilizeComponent();
  }

  initilizeComponent() {
    try {
      if (!this.countryBe) {
        if (this.data.getCountryId() !== null) {
          this.dlxClient.GetCountry(this.data.getCountryId()!).subscribe({
            next: (response) => {
              this.countryBe = response;
            }
          })
        }
        else {
          this.dlxClient.GetCountryByCode('NO').subscribe({
            next: (response) => {
              this.countryBe = response;
            }
          })
        }

      }


      this.emailAddress = this.data.getEmailAddress();
      this.invoiceEmailAddress = this.data.getEmailAddressInvoice();
      this.webAddress = this.data.getWebAddress();
      this.currentPhoneNumber = this.phonesNumbers.find(el => el.PhoneTypeId === this.currentPhoneTypeId)?.Number || null;
      this.ehfInvoice = this.data.getEHFInvoice();
      this.vatObligated = this.data.getVatObligated();
      this.digipostInvoice = this.data.getDigipostInvoice();
      if (this.digipostInvoice !== null) this.digipostInvoiceIndetermined = false;
      this.emailInvoice = this.data.getEmailInvoice();
      if (this.emailInvoice !== null) this.emailInvoiceIndetermined = false;
      this.extractAddresses(JSON.parse(JSON.stringify(this.data.getAddresses())));


      this.inititalized = true;

    }
    catch (error) {
      setTimeout(() => {

        this.initilizeComponent();

      }, 100)

    }
  }

  setPhoneArray() {
    this.phonesNumbers = [];
    this.phoneNumberTypes.forEach(type => {
      this.phonesNumbers.push({
        IsNew: true,
        IsDeleted: false,
        Id: EmptyGuid,
        ContactId: EmptyGuid,
        PhoneTypeId: type.Id,
        PhoneType: type.Name,
        Number: "",
        CountryCode: "",
        Extension: null,
        Timestamp: []
      })
    })

    const existingNumbers: IContactPhoneBE[] | null = JSON.parse(JSON.stringify(this.data.getPhones()));
    if (existingNumbers === null) return
    existingNumbers.forEach(num => {
      const index = this.phonesNumbers.findIndex(n => n.PhoneTypeId === num.PhoneTypeId);
      if (index === -1) return
      this.phonesNumbers[index] = num;
    });
  }



  countryBe!: ICountryBE;
  addressTypes = AddressTypes;
  phoneNumberTypes = PhoneNumberTypesArray;
  phonesNumbers!: IContactPhoneBE[];
  currentPhoneTypeId = PhoneNumberTypeIdEnum.Mobile;
  currentPhoneNumber!: string | null;

  onPhoneTypeChange(e: string) {
    if (!e) return
    const existingNumber = this.phonesNumbers.find(el => el.PhoneTypeId === e)!;
    this.currentPhoneNumber = existingNumber.Number ?? "";
  }

  onPhoneInputChange(e: any) {
    switch (this.currentPhoneTypeId) {
      case PhoneNumberTypeIdEnum.Private:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Private))
        break;
      case PhoneNumberTypeIdEnum.Mobile:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Mobile))
        break;
      case PhoneNumberTypeIdEnum.Work:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Work))
        break;
      case PhoneNumberTypeIdEnum.Div:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Div))
        break;
      case PhoneNumberTypeIdEnum.Fax:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Fax))
        break;
      case PhoneNumberTypeIdEnum.Vacation:
        this.setPhoneNumber(this.phonesNumbers.findIndex(type => type.PhoneTypeId === PhoneNumberTypeIdEnum.Vacation))
        break;
      default:
        throw new Error('Uknown phonenumber type')
    }
  }

  setPhoneNumber(index: number) {
    if (this.currentPhoneNumber) {
      this.phonesNumbers[index].Number = this.currentPhoneNumber;
    } else {
      this.phonesNumbers[index].IsDeleted = true;
    }

    this.data.setPhones(this.phonesNumbers.filter(n => n.Number !== "" || n.IsDeleted === true));
  }


  addressTypeChange(type: string) {
    this._activeType = type;
  }

  addressDropdownData = [
    {
      Id: "post",
      Name: "Postadresse"
    },
    {
      Id: "visit",
      Name: "Besøksadresse"
    },
    {
      Id: "invoice",
      Name: "Fakturaadresse"
    },
    {
      Id: "registered",
      Name: "Folkeregisteradresse"
    },
  ]

  _activeType = "post"
  postalAddress!: IPostalAddressBE;

  postAddress!: string;
  visitAddress!: string;
  registeredAddress!: string;
  invoiceAddress!: string;

  postAddressObj!: IContactAddressBE | null;
  visitAddressObj!: IContactAddressBE | null;
  invoiceAddressObj!: IContactAddressBE | null;
  registeredAddressObj!: IContactAddressBE | null;

  adressesToSave() {
    return [this.postAddressObj, this.visitAddressObj, this.registeredAddressObj, this.invoiceAddressObj].filter(adr => adr?.Address && adr?.PostalAddress?.PostCode !== "" || adr?.IsDeleted)
  }

  setAddressObj(obj: IContactAddressBE, name: string) {    
    if(obj === null || obj.Address === null) return
    if (!obj.Address.trim()) {
      //@ts-ignore
      this[name].IsDeleted = true;
      this.data.setAddresses(this.adressesToSave() as IContactAddressBE[])
      return
    }
    //@ts-ignore
    this[name] = obj;
    //@ts-ignore
    this[name].IsDeleted = false;
    
    this.data.setAddresses(this.adressesToSave() as IContactAddressBE[])
    

  }

  checkBoxChange(event: any, target: 'setVatObligated' | 'setEmailInvoice' | 'setDigipostInvoice') {
    //@ts-ignore
    this.data[target](event.target!.checked);
  };


  extractAddresses(addresses: IContactAddressBE[] | null) {
    if (addresses === null) return
    const visit = addresses.filter(adr => adr.AddressTypeId === AddressTypeIdEnum.VisitAddress)[0];
    const post = addresses.filter(adr => adr.AddressTypeId === AddressTypeIdEnum.PostAddress)[0];
    const registered = addresses.filter(adr => adr.AddressTypeId === AddressTypeIdEnum.RegisteredAddress)[0];
    const invoice = addresses.filter(adr => adr.AddressTypeId === AddressTypeIdEnum.InvoiceAddress)[0];

    if (visit) {
      this.visitAddressObj = visit;
      this.visitAddress = this.visitAddressObj.Address
    }
    if (post) {
      this.postAddressObj = post;
      this.postAddress = this.postAddressObj.Address
    }
    if (registered) {
      this.registeredAddressObj = registered;
      this.registeredAddress = this.registeredAddressObj.Address
    }
    if (invoice) {
      this.invoiceAddressObj = invoice;
      this.invoiceAddress = this.invoiceAddressObj.Address
    }
  }

  hideErrorMessage(inputName: string) {
    if (inputName === 'emailAddress') {
      this.showEmailErrorMessage = false;
    } else if (inputName === 'emailAddressInvoice') {
      this.showInvoiceEmailErrorMessage = false;
    }
  }

  showErrorMessageOnBlur(inputName: string) {
    if (inputName === 'emailAddress' && this.emailAddressCtrl.invalid) {
      this.showEmailErrorMessage = true;
    } else if (inputName === 'emailAddressInvoice' && this.invoiceEmailAddressCtrl.invalid) {
      this.showInvoiceEmailErrorMessage = true;
    }
  }

}


// Define a base type for phone number objects to avoid repetition
type BasePhoneNumber = {
  ContactId: string;
  CountryCode: string;
  Extension: string | null;
  Id: string;
  IsDeleted: boolean;
  IsNew: boolean;
  Number: string;
  Timestamp: [];
};

// Extend the base type for specific phone types
type WorkPhoneNumber = BasePhoneNumber & { PhoneType: "Telefon Arbeid"; PhoneTypeId: PhoneNumberTypeIdEnum.Work };
type MobilePhoneNumber = BasePhoneNumber & { PhoneType: "Mobil"; PhoneTypeId: PhoneNumberTypeIdEnum.Mobile };
type FaxPhoneNumber = BasePhoneNumber & { PhoneType: "Faks"; PhoneTypeId: PhoneNumberTypeIdEnum.Fax };
type VacationPhoneNumber = BasePhoneNumber & { PhoneType: "Telefon Ferie"; PhoneTypeId: PhoneNumberTypeIdEnum.Vacation };
type DivPhoneNumber = BasePhoneNumber & { PhoneType: "Telefon diverse"; PhoneTypeId: PhoneNumberTypeIdEnum.Div };
type PrivatePhoneNumber = BasePhoneNumber & { PhoneType: "Telefon privat"; PhoneTypeId: PhoneNumberTypeIdEnum.Private };

// Define the TPhoneNumberArray type as a tuple
export type TPhoneNumberArray = [
  WorkPhoneNumber,
  MobilePhoneNumber,
  FaxPhoneNumber,
  VacationPhoneNumber,
  DivPhoneNumber,
  PrivatePhoneNumber
];