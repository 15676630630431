import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrregSearchService {

  private $UNIT_URL = 'https://data.brreg.no/enhetsregisteret/api/enheter/' as const;

  constructor() {

  }

  async getUnitByOrganizationNumber(organizationNumber: string): Promise<Unit> {
    const response = await fetch(`${this.$UNIT_URL}${organizationNumber}`);
    return response.json();
  }
}


export interface Unit {
  organisasjonsnummer: string;
  navn: string;
  organisasjonsform: {
    kode: string;
    beskrivelse: string;
    utgaatt?: string;
    _links?: {
      self: {
        href: string;
      };
    };
  };
  postadresse?: Address;
  forretningsadresse?: Address;
  registreringsdatoEnhetsregisteret: string;
  registrertIMvaregisteret: boolean;
  maalform: "Bokmål" | "Nynorsk" | "Engelsk";
  naeringskode1?: IndustryCode;
  naeringskode2?: IndustryCode;
  naeringskode3?: IndustryCode;
  hjelpeenhetskode?: IndustryCode;
  underAvvikling: boolean;
  underAvviklingDato?: string;
  konkurs: boolean;
  konkursdato?: string;
  tvangsavvikletPgaManglendeSlettingDato?: string;
  tvangsopplostPgaManglendeDagligLederDato?: string;
  tvangsopplostPgaManglendeRevisorDato?: string;
  tvangsopplostPgaManglendeRegnskapDato?: string;
  tvangsopplostPgaMangelfulltStyreDato?: string;
  vedtektsdato?: string;
  vedtektsfestetFormaal?: string[];
  aktivitet?: string[];
  registrertIStiftelsesregisteret: boolean;
  registrertIFrivillighetsregisteret: boolean;
  stiftelsesdato?: string;
  institusjonellSektorkode?: {
    kode: string;
    beskrivelse: string;
  };
  registrertIForetaksregisteret: boolean;
  hjemmeside?: string;
  sisteInnsendteAarsregnskap?: string;
  frivilligMvaRegistrertBeskrivelser?: string[];
  underTvangsavviklingEllerTvangsopplosning: boolean;
  antallAnsatte?: number;
  harRegistrertAntallAnsatte: boolean;
  overordnetEnhet?: string;
  registreringsdatoAntallAnsatteNAVAaregisteret?: string;
  registreringsdatoAntallAnsatteEnhetsregisteret?: string;
  registreringsdatoMerverdiavgiftsregisteret?: string;
  registreringsdatoMerverdiavgiftsregisteretEnhetsregisteret?: string;
  registreringsdatoFrivilligMerverdiavgiftsregisteret?: string;
  registreringsdatoForetaksregisteret?: string;
  registreringsdatoFrivillighetsregisteret?: string;
  registrertIPartiregisteret: boolean;
  registreringsdatoPartiregisteret?: string;
  epostadresse?: string;
  telefon?: string;
  mobil?: string;
  _links?: {
    overordnetEnhet?: {
      href: string;
    };
    self: {
      href: string;
    };
  };
}

export interface Address {
  land: string;
  landkode: string;
  postnummer: string;
  poststed: string;
  adresse: string[];
  kommune?: string;
  kommunenummer?: string;
}

export interface IndustryCode {
  kode: string;
  beskrivelse: string;
}